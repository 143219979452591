import React, { useState } from "react";
import { Link } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import { useAuth0 } from "@auth0/auth0-react";

const ReserveCard = ({
  card,
  isGrid,
  setIsModalOpen,
  setOpenContactForm,
  setSelectedType,
}) => {
  const locale = useStorage();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleAddSearchParams = () => {
    window.history.replaceState(null, null, "?go&type=privatepods");
    setIsModalOpen(true);
  };

  const handleLink = () => {
    window.open(card.link);
  };

  return (
    <div
      className={`min-w-[285px] md:h-[293px] bg-primaryBg rounded-[8px] p-[24px] ${
        isGrid ? "w-full" : "w-1/3"
      }`}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <img className="w-[48px] mt-[21px]" src={card.image} alt="" />
        </div>
        <div>
          <h4 className="text-white text-[20px] md:text-[27px] mb-[8px]">
            {card.title}
          </h4>
          <p className="text-white text-[12px] md:text-[16px] mb-[24px]">
            {card.desc}
          </p>
          {/* NEW: For cards with a link, display a button just below the description */}
          {card.link &&
            !(card.like === "reserve_book" || card.like === "event_tickets") &&
            (isAuthenticated ? (
              <button
                onClick={handleLink}
                className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
              >
                {language.read_title[locale]}
              </button>
            ) : (
              <button
                onClick={loginWithRedirect}
                className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
              >
                {language.login[locale]}
              </button>
            ))}
        </div>
        {/* Existing conditional button/link for other cases */}
        {(card.like === "reserve_book" || card.like === "event_tickets") ? (
          <Link
            to={card.like === "event_tickets" ? "/happenings" : "/library"}
            className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
          >
            {card.like === "event_tickets"
              ? language.book[locale]
              : language.read_title[locale]}
          </Link>
        ) : (
          // Render the default button only if no card.link exists (since the link case is handled above)
          !card.link && (
            <button
              onClick={() => {
                if (card.pod) {
                  handleAddSearchParams();
                } else {
                  setOpenContactForm(true);
                  setSelectedType(card.type);
                }
              }}
              className="flex justify-center items-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
            >
              {language.book[locale]}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default ReserveCard;
